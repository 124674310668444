$(document).on("turbolinks:load", function () {
    //zoom on image
    function imageZoom(imgID, resultID) {

        var img, lens, result, cx, cy;
        img = document.getElementById(imgID);
        result = document.getElementById(resultID);
        img.onload=function () {
        /*create lens:*/
        lens = document.createElement("DIV");
        lens.setAttribute("class", "img-zoom-lens");
        /*insert lens:*/
        img.parentElement.insertBefore(lens, img);
        /*calculate the ratio between result DIV and lens:*/
        cx = result.offsetWidth / lens.offsetWidth;
        cy = result.offsetHeight / lens.offsetHeight;
        /*set background properties for the result DIV:*/
        result.style.backgroundImage = "url('" + img.src + "')";
        result.style.backgroundSize = (this.width * cx) + "px " + (this.height * cy) + "px";
        
        /*execute a function when someone moves the cursor over the image, or the lens:*/
        lens.addEventListener("mousemove", moveLens);
        img.addEventListener("mousemove", moveLens);
        /*and also for touch screens:*/
        lens.addEventListener("touchmove", moveLens);
        img.addEventListener("touchmove", moveLens);
        function moveLens(e) {
          var pos, x, y;
          /*prevent any other actions that may occur when moving over the image:*/
          e.preventDefault();
          /*get the cursor's x and y positions:*/
          pos = getCursorPos(e);
          /*calculate the position of the lens:*/
          x = pos.x - (lens.offsetWidth / 2);
          y = pos.y - (lens.offsetHeight / 2);
          /*prevent the lens from being positioned outside the image:*/
          if (x > img.width - lens.offsetWidth) {x = img.width - lens.offsetWidth;}
          if (x < 0) {x = 0;}
          if (y > img.height - lens.offsetHeight) {y = img.height - lens.offsetHeight;}
          if (y < 0) {y = 0;}
          /*set the position of the lens:*/
          lens.style.left = x + "px";
          lens.style.top = y + "px";
          /*display what the lens "sees":*/
          result.style.backgroundPosition = "-" + (x * cx) + "px -" + (y * cy) + "px";
        }
        function getCursorPos(e) {
          var a, x = 0, y = 0;
          e = e || window.event;
          /*get the x and y positions of the image:*/
          a = img.getBoundingClientRect();
          /*calculate the cursor's x and y coordinates, relative to the image:*/
          x = e.pageX - a.left;
          y = e.pageY - a.top;
          /*consider any page scrolling:*/
          x = x - window.pageXOffset;
          y = y - window.pageYOffset;
          return {x : x, y : y};
        } 
        }
      }
    // set location for submission
    if($(".catch_submissions_new").length) {
        $("#catchSubmission").on('click', function (e) {

            if ($("#catchSubmission").is(':checked')) {
                e.preventDefault();
                $(".catch-submission-spinner").show();
                $("#catchSubmission").hide();
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(setPosition);
                }
                function setPosition(position) {
                    $("#catch_submission_latitude").val(position.coords.latitude); 
                    $("#catch_submission_longitude").val(position.coords.longitude); 
                    if($("#catch_submission_latitude").val() != '' && $("#catch_submission_longitude").val() != ''){
                        $("#catchSubmission").prop("checked", true);
                        $(".catch-submission-spinner").hide();
                        $("#catchSubmission").show();
                    }
                }
            } else {
                $("#catch_submission_latitude").val(""); 
                $("#catch_submission_longitude").val(""); 
                $("#catchSubmission").prop("checked", false);
            }
        });

    }
    // ajax to load submission details
    $(".current-submission").on('click', function(e) {
        e.preventDefault();
        var $currentSubmission = $(e.target).parent(".current-submission")
        if ($(".current_catch_submission_details-" + $currentSubmission.attr("data-submission-id")).children().length == 0 ) {
            $.ajax({
                url: `/tournaments/${$currentSubmission.attr("data-tournament-id")}/catch_submissions/${$currentSubmission.attr("data-submission-id")}/details`,
                type: "GET",
                data: {id: $(".current-submission").attr("data-submission-id")},
                success: function(resp){
                    $(".catch_submission_details").children().remove()
                    $(".current_catch_submission_details-" + $currentSubmission.attr("data-submission-id")).html(resp.html)       
                    imageZoom("catchSubmissionImage", "catchSubmissionImageZoomed");
                    const reduceCount = (countObject) => {
                        const unverifiedCount = countObject;
                        if ($(unverifiedCount) !== undefined) {
                          const reducedCount = parseInt(unverifiedCount.text(), 10) - 1;
                          unverifiedCount.html(reducedCount);
                        }
                    };
                    $(".approve_button").on("click", (e) => {

                        const approveSection = $(e.target).closest('.approve_section');
                        const submissionSection = $(e.target).closest(".catch_submission_details").prev('.submission_section').find(".status_section");
                        submissionSection.html("<span>Verified</span>");
                        if ($(approveSection) !== undefined) {
                          approveSection.html("<span>Verified</span>");
                        }
                        reduceCount($('#unverified-circle'));
                      });
                    
                      $(".reject_button").on("click", (e) => {
                          e.preventDefault();
                        const submissionSection = $(e.target).closest(".catch_submission_details").prev('.submission_section');
                        const submissionDetails = $(e.target).closest(".catch_submission_details");
                        const currentModalTitle = $(e.target).closest(".modal-body").prev(".modal-header").find(".modal-title");
                        const totalSubmissions = currentModalTitle.html().split("(")[1].split(")")[0];
                        const newTitle = "Manage Catch Submissions (" + (totalSubmissions - 1) + ")";

                        if ($(submissionSection) !== undefined) {
                          submissionSection.remove();
                          submissionDetails.remove();
                        }
                        currentModalTitle.html(newTitle);
                        reduceCount($('#unverified-circle'));
                        reduceCount($('#submissions-circle'));
                      });
                }
              }).catch(function(e) {
                alert("There was an error loading the catch submission details.")
              });
        } else {
            $(".current_catch_submission_details-" + $currentSubmission.attr("data-submission-id")).children().remove()
        }
      });

  });