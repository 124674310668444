$(document).on("turbolinks:load", function () {
  var onBillingPage = $('#settings-billing').length;
  if (!onBillingPage) {
    return;
  }

  $('#subscription-agreement, #terms-agreement').on("change", function(event) {
    if ($('#subscription-agreement').is(':checked') && $('#terms-agreement').is(':checked')) {
      $('#submit-subscription-payment-container').show();
    } else {
      $('#submit-subscription-payment-container').hide();
    }
  });

  $.ajax({
    url: `/settings/create_customer`,
    type: "PUT",
    error: function(e) {
      //To Do: More intelligent error handling
      alert("There was an error processing your payment. Please refresh the page.")
    },
    statusCode: {
      401: function() {
        //To Do: More intelligent error handling
        alert("There was an error processing your payment. Please refresh the page.")
      },
      200: function() {
        if (Stripe && gon.stripe_publishable_api) {
          var stripe = Stripe(gon.stripe_publishable_api);
        };

        
        if (stripe) {
          var elements = stripe.elements();
          var cardElement = $("#settings-card-element");
          
          // If the element doesn't exist, it returns an empty array
          if (cardElement.length) {
            // Stripe specific functions  
            var style = {
              base: {
                color: "#32325d",
              }
            };
      
            var card = elements.create("card", { style: style });
            card.mount("#settings-card-element")
      
            card.on('change', ({complete, error}) => {
              let displayError = document.getElementById('settings-card-errors');
              if (error) {
                displayError.textContent = error.message;
              } else if (complete) {
                $('.submit-subscription').prop('disabled', false);
              } else {
                $('.submit-subscription').prop('disabled', true);
                displayError.textContent = '';
              }
            });
          };

          $("body").on("click", ".submit-subscription", function (e) {
            e.preventDefault();
            $('.submit-text').hide();
            $('.submit-spinner').show();
            var cardName = $('.billing-name').value
            stripe.createPaymentMethod({
              type: 'card',
              card,
              billing_details: {
                name: cardName,
              }
            })
            .then(function (res) {
              const { error, paymentMethod } = res;
              if (paymentMethod) {
                $.ajax({
                  url: `/settings/create_subscription`,
                  type: "PUT",
                  data: {payment_method_id: paymentMethod.id},
                  error: function(e) {
                    //To Do: More intelligent error handling
                    alert("There was an error processing your payment. Please refresh the page.")
                  },
                })
              } else if (error) {
                // Error event is sent, so #settings-card-errors is updated automatically
                $('.submit-text').show();
                $('.submit-spinner').hide();
              } else {
                //To Do: More intelligent error handling
                alert("There was an error processing your payment. Please refresh the page.")
              }
            })
            .catch(function (error) {
              alert("There was an error processing your payment. Please refresh the page.")
            });
          });
        };
      }
    },
  }).catch(function(e) {
    //To Do: More intelligent error handling
    alert("There was an error processing your payment. Please refresh the page.")
  });
});