// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require("jquery-mask-plugin");
import "bootstrap";

import "moment";
import "moment-timezone";
import "bootstrap-daterangepicker";
import "rangeslider.js";
import "dropzone";
import "bootstrap-select";

// please keep in alpha order
import "./anglers";
import "./billing";
import "./catchSubmissions.js";
import "./dropzone-configs.js";
import "./ekko-lightbox.min.js";
import "./home";
import "./register";
import "./settings";
import "./tournaments.js";
import "./trips";
import "./userRegistration";
import "./filters";


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

$(document).on("turbolinks:load", function () {
  /*
    Popovers: enabled below
    If concerned about performance enable only on pages where they are used
    https://getbootstrap.com/docs/4.0/components/popovers/
  */
  $('[data-toggle="popover"]').popover();
  $(window).trigger('load.bs.select.data-api');
  $('.selectpicker').selectpicker();
  $('.selectpicker-filter').selectpickerFilter();

  // TOURNAMENTS COUNTDOWN
  var moment = require('moment')
  $(".countdown").each(function (index) {
    // var countDownDate = new Date(($(this).data("finishtime")).replace(/\s/, 'T')).getTime();
    var countDownDate = moment.utc($(this).data("finishtime"))
    var endDate = moment.utc($(this).children(".end-date").data("enddate"));
    var $item = $(this);

    var x = setInterval(function () {
      var now = moment.utc();
      var today = new Date();

      // // Tournament isn't complete until the next day
      // endDate.setDate(endDate.getDate() + 1);
      var distance = countDownDate.diff(now);
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);

      $item.find(".c-days").html(days);
      $item.find(".c-hours").html(hours);
      $item.find(".c-mins").html(minutes);
      $item.find(".c-secs").html(seconds);

      if (endDate && (now >= endDate)) {
        clearInterval(x);
        $item.html(
          '<li class="d-block w-100"><div class="alert mb-0 h6 d-block">Completed</div></li>'
        );
      } else if (distance < 0) {
        clearInterval(x);
        $item.html(
          '<li class="d-block w-100"><div class="alert mb-0 h6 d-block">Started</div></li>'
        );
      }
    }, 1000);
  });

  var moment = require('moment');
  var user_timezone = moment.tz.guess();

  $(".convert_date").each(function (index) {
    var date = $(this).html();
    $(this).html( moment(date).tz(user_timezone).format('MMM DD, YYYY • h:mm a'));
  });

  $(".convert_date_start").each(function (index) {
    var date = $(this).html();
    $(this).html( moment(date).tz(user_timezone).format('MMM DD, YYYY'));
  });

  $(".convert_date_end").each(function (index) {
    var date = $(this).html();
    $(this).html( moment(date).tz(user_timezone).format('h:mm a'));
  });

  $(".convert_date_shortstart").each(function (index) {
    var date = $(this).html();
    $(this).html( moment(date).tz(user_timezone).format('MMM DD'));
  });
  $(".convert_date_shortend").each(function (index) {
    var date = $(this).html();
    $(this).html( moment(date).tz(user_timezone).format('DD, YYYY'));
  });

  // expand and collapse filters 
  $(".collapse-filter").on("click", function () {
    $(".mobile-filter").addClass("d-none");
    $(".collapse-filter").addClass("d-none");
    $(".expand-filter").removeClass("d-none");

  })
  $(".expand-filter").on("click", function () {
    $(".mobile-filter").removeClass("d-none");
    $(".collapse-filter").removeClass("d-none");
    $(".expand-filter").addClass("d-none");
  })

//tooltip for filters 
  $('[data-toggle-filter="tooltip"]').tooltip();
  
});

$.jMaskGlobals.watchDataMask = true;

