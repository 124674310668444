$(document).on("turbolinks:load", function () {
  // Featured Catch Carousel JS
  // triggered when sliding starts 
  $('#carouselCatch #featuredGuidesSlider #featuredCaptainsSlider').on('slide.bs.carousel', function () {
    $('.carousel-caption, .carousel-indicators, .carousel-control-prev, .carousel-control-next').hide();
  })

  // triggered when sliding is complete
  $('#carouselCatch #featuredGuidesSlider #featuredCaptainsSlider').on('slid.bs.carousel', function () {
    $('.carousel-caption, .carousel-indicators, .carousel-control-prev, .carousel-control-next').show();
  })
});
