$(document).on("turbolinks:load", function () {
  //enable profile__stats tooltip
  $('.profile__stats').tooltip()

  // Show past tournaments tab if there is no tournaments currently running
  if(gon.running_count === 0) {
    if(gon.upcoming_count === 0) {
      $('a[href="#nav-past"]').tab('show');
    } else {
      $('a[href="#nav-upcoming"]').tab('show');
    }
  }
});
