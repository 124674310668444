var moment = require('moment')

$(document).on("turbolinks:load", function () {
  //enable entry level tooltop
  $(".entry_level_warning").tooltip()
  $(".prizebox").tooltip()
  $(".tournament_registration_ends_at").tooltip()

  $('input[name="datefilter"]').daterangepicker({
    autoUpdateInput: false,
    locale: {
      cancelLabel: "Clear"
    }
  });

  $('input[name="datefilter"]').on("apply.daterangepicker", function (ev, picker) {
    $(this).val(
        picker.startDate.format("MM/DD/YYYY") + " - " + picker.endDate.format("MM/DD/YYYY")
    );
  });

  $('input[name="datefilter"]').on("cancel.daterangepicker", function (ev, picker) {
    $(this).val("");
  });

  // Initialize a new plugin instance for all
  // e.g. $('input[type="range"]') elements.
  // $('input[type="range"]').rangeslider();
  // $('input[type="range"]').rangeslider({ polyfill: false });

  // Destroy all plugin instances created from the
  // e.g. $('input[type="range"]') elements.
  // $('input[type="range"]').rangeslider('destroy');

  // Update all rangeslider instances for all
  // e.g. $('input[type="range"]') elements.
  // Usefull if you changed some attributes e.g. `min` or `max` etc.
  // $('input[type="range"]').rangeslider('update', true);

  $('input[type="range"]').rangeslider({
    // Feature detection the default is `true`.
    // Set this to `false` if you want to use
    // the polyfill also in Browsers which support
    // the native <input type="range"> element.
    polyfill: false,

    // Default CSS classes
    rangeClass: "rangeslider",
    disabledClass: "rangeslider--disabled",
    horizontalClass: "rangeslider--horizontal",
    verticalClass: "rangeslider--vertical",
    fillClass: "rangeslider__fill",
    handleClass: "rangeslider__handle",

    // Callback function
    onInit: function () {},

    // Callback function
    onSlide: function (position, value) {},

    // Callback function
    onSlideEnd: function (position, value) {}
  });





  // PHOTO LIGHTBOX
  $(document).on('click', '[data-toggle="lightbox"]', function(event) {
    event.preventDefault();
    $(this).ekkoLightbox({
      alwaysShowClose: true
    });
  });





  // CUSTOM FILE INPUTS
  $(document).on('change', '.custom-file-input', function(e) {
    e.preventDefault();
    var $fileName = $(this).val();
    var $cleanFilename = $fileName.replace(/^C:\\fakepath\\/, "");

    // If there is an error rails will place "field with errors" above
    // the element which messes with the .next call
    if ($(this).parent().attr("class") === "field_with_errors"){
      $(this).parent().next('.custom-file-label').html($cleanFilename);
    } else {
      $(this).next('.custom-file-label').html($cleanFilename);
    }
  })




  $(document).on('change', '#tournament_images, #tournament_sponsors', function() {
    var $gallery = $(this).closest('.gallery-wrapper');
    var $photo_gallery = $gallery.find(".images_gallery");
    var $gallery_placeholder = $gallery.find(".gallery_placeholder");
    var $inEditingMode = $gallery.hasClass('inEditingMode');

    if (!$($gallery.find(".images_gallery")).length) {
      $gallery_placeholder.html('<div class="panelbox mb-3"><div class="panelbox__body panelbox_body--bordertop img-container"><div class="row images_gallery"></div></div></div>');
      var $target_gallery = $gallery_placeholder.find('.images_gallery');
    } else {
      var $target_gallery = $photo_gallery;
    }
    if (!$inEditingMode) {
      $target_gallery.html('');
    }
    if ($inEditingMode) {
      $target_gallery.find('.image:not(.saved_image)').remove();
    }
    for (var i = 0; i < $(this)[0].files.length; i++) {
      $target_gallery.append('<div class="col-12 col-md-3 pb-3 image"><img src="'+window.URL.createObjectURL(this.files[i])+'" class="img-fluid" /><button type="button" class="btn btn-block btn-danger delete_photo d-none">Delete</button></div>');
    }
  });





  // TOURNAMENT PRIZE
  $(".entrylevels").on("input change", ".prize_pool", function () {

    var $prize_pool = $(this).val();
    var $prize_card = $(this).closest('.prizecard');

    if ($(this).val() === "") {
      $prize_card.find(".rangebox").addClass("rangebox--disabled");
      $prize_card.find(".prize_1, .prize_1_value").prop("value", 0);
      $prize_card.find(".prize_2, .prize_2_value").prop("value", 0);
      $prize_card.find(".prize_3, .prize_3_value").prop("value", 0);
    } else {
      $prize_card.find(".rangebox").removeClass("rangebox--disabled");
      $prize_card.find('input[type="range"]').prop("max", $prize_pool);
      $prize_card.find(".prize_1, .prize_1_value").prop("value", parseFloat($prize_pool * 0.5).toFixed(0));
      $prize_card.find(".prize_2, .prize_2_value").prop("value", parseFloat($prize_pool * 0.2).toFixed(0));
      $prize_card.find(".prize_3, .prize_3_value").prop("value", parseFloat($prize_pool * 0.1).toFixed(0));
    }

    $prize_card.find('input[type="range"]').rangeslider("update", true);

    setTimeout(function () {
      // $('input[type="range"]').rangeslider('update', true);
    }, 500);
  });

  $('.entrylevels').on("input change", '.prize_range', function () {

    var $prize_card = $(this).closest('.prizecard');
    var $prize_pool = parseInt($prize_card.find(".prize_pool").val());

    var $range1 = parseInt($prize_card.find(".prize_1_value").val());
    var $range2 = parseInt($prize_card.find(".prize_2_value").val());
    var $range3 = parseInt($prize_card.find(".prize_3_value").val());

    var $maxrange1 = $prize_pool - $range2 - $range3;
    var $maxrange2 = $prize_pool - $range1 - $range3;
    var $maxrange3 = $prize_pool - $range1 - $range2;

    $prize_card.find(".prize_1, .prize_1_value").prop("max", $maxrange1);
    $prize_card.find(".prize_2, .prize_2_value").prop("max", $maxrange2);
    $prize_card.find(".prize_3, .prize_3_value").prop("max", $maxrange3);

    $(this).closest(".rangebox").find(".form-control").val(parseFloat($(this).val()).toFixed(0));

    $prize_card.find('input[type="range"]').rangeslider("update", true);
  });


  $(".entrylevels").on("input change", ".prize_value", function () {

    var $prize_card = $(this).closest('.prizecard');
    var $prize_pool = parseInt($prize_card.find(".prize_pool").val());

    var $this_prize_value = parseInt($(this).val());

    var $range1 = parseInt($prize_card.find(".prize_1_value").val());
    var $range2 = parseInt($prize_card.find(".prize_2_value").val());
    var $range3 = parseInt($prize_card.find(".prize_3_value").val());

    var $maxrange1 = $prize_pool - $range2 - $range3;
    var $maxrange2 = $prize_pool - $range1 - $range3;
    var $maxrange3 = $prize_pool - $range1 - $range2;

    $(this).closest(".rangebox").find('input[type="range"]').prop("value", parseFloat($this_prize_value).toFixed(0));

    $prize_card.find(".prize_1, .prize_1_value").prop("max", $maxrange1);
    $prize_card.find(".prize_2, .prize_2_value").prop("max", $maxrange2);
    $prize_card.find(".prize_3, .prize_3_value").prop("max", $maxrange3);

    $prize_card.find('input[type="range"]').rangeslider("update", true);
  });

  $('input[type="number"]').on('keyup',function(){
    var v = parseInt($(this).val());
    var min = parseInt($(this).attr('min'));
    var max = parseInt($(this).attr('max'));

    if (v < min) {
      $(this).val(min);
    } else if (v > max){
      $(this).val(max);
    }
  })


  function update_fishtypes() {
    var $entrylevels = $('.entrylevels');
    $entrylevels.find(".prizecard").each(function() {
      var $current_fishtypes = $(this).find('.prize_fishtypes');
      var $selected_fishtypemetric = $current_fishtypes.val();
      if ($selected_fishtypemetric) { 
        $selected_fishtypemetric = $selected_fishtypemetric.split('___');
        $(this).find('.prize_fishtype').val($selected_fishtypemetric[0]);
        $(this).find('.prize_metric').val($selected_fishtypemetric[1]);
      }
    })
  }


  //update Entry Levels
  if ($('.tournaments_edit').length) {
    populateUpdateBaseEntryForm();
    populateUpdateEntryLevelForm();
  }

  // PRIZE GIFTS 
  if ($('.tournaments_edit').length || $('.tournaments_new').length) {

    //add new base prize gift
    $('.entrylevels').on('click', '.addBaseEntryPrizeGift', (e) => {
      e.preventDefault()
      var prizeGiftIndex = $(e.target).parents('.prizeContainer').find('.giftPrize').length
      var $newPrizeGift = $(e.target).parents('.entrylevel').find('.prizeGiftContainerTemplate .giftPrize').clone()
      $newPrizeGift.removeClass('d-none')
      $newPrizeGift.addClass('activePrizeGift')
      $newPrizeGift.find('.prize_gifts_name').prop("disabled", false)
      $newPrizeGift.find('.prize_gifts_description').prop("disabled", false)
      $newPrizeGift.find('.prize_gifts_image').prop("disabled", false)
      $newPrizeGift.find('.prize_gifts_place').prop("disabled", false)
      $newPrizeGift.find('.prize_gifts_place').prop("name", 'tournament[base_entry_attributes[prize_gifts_attributes]['+prizeGiftIndex+'][place]]')
      $newPrizeGift.find('.prize_gifts_name').prop("name", 'tournament[base_entry_attributes[prize_gifts_attributes]['+prizeGiftIndex+'][name]]')
      $newPrizeGift.find('.prize_gifts_description').prop("name", 'tournament[base_entry_attributes[prize_gifts_attributes]['+prizeGiftIndex+'][description]]')
      $newPrizeGift.find('.prize_gifts_image').prop("name", 'tournament[base_entry_attributes[prize_gifts_attributes]['+prizeGiftIndex+'][image]]')
      $newPrizeGift.find('.prize_gifts_place').val($(e.target).data('prize-place'))
      $newPrizeGift.appendTo($(e.target).parents('.prizeGiftContainer'))
    })

    // add additional entry level prize gift
    $('.entrylevels').on('click', '.addAdditionalEntryLevelPrizeGift', (e) => {
      e.preventDefault()
      var prizeGiftIndex = $(e.target).parents('.prizeContainer').find('.giftPrize').length
      var $newPrizeGift = $(e.target).parents('.entrylevel').find('.prizeGiftContainerTemplate .giftPrize').clone()
      var entryLevelIndex = $(e.target).parents('.entrylevel').index() -1
      $newPrizeGift.removeClass('d-none baseGiftPrize')
      $newPrizeGift.addClass('activePrizeGift additionalGiftPrize')
      $newPrizeGift.find('.prize_gifts_name').prop("disabled", false)
      $newPrizeGift.find('.prize_gifts_description').prop("disabled", false)
      $newPrizeGift.find('.prize_gifts_image').prop("disabled", false)
      $newPrizeGift.find('.prize_gifts_place').prop("disabled", false)
      $newPrizeGift.find('.prize_gifts_place').prop('name', "[tournament][entry_levels_attributes][" + entryLevelIndex + "][prize_gifts_attributes][" + prizeGiftIndex + "][place]")
      $newPrizeGift.find('.prize_gifts_name').prop('name', "[tournament][entry_levels_attributes][" + entryLevelIndex + "][prize_gifts_attributes][" + prizeGiftIndex + "][name]")
      $newPrizeGift.find('.prize_gifts_description').prop('name', "[tournament][entry_levels_attributes][" + entryLevelIndex + "][prize_gifts_attributes][" + prizeGiftIndex + "][description]")
      $newPrizeGift.find('.prize_gifts_image').prop('name', "[tournament][entry_levels_attributes][" + entryLevelIndex + "][prize_gifts_attributes][" + prizeGiftIndex + "][image]")
      $newPrizeGift.find('.prize_gifts_place').val($(e.target).data('prize-place'))
      $newPrizeGift.appendTo($(e.target).parents('.prizeGiftContainer'))
    })
    //add delete new gift gift

    $('.entrylevels').on("click", '.deleteNewPrizeGift', function (e) {
      var $prizeGift = $(e.target).parents('.giftPrize')
      deactiveFirstGiftPrize($prizeGift)
    })
    $("body").on("click", ".deleteExistingBasePrizeGift", function (e) {
      e.preventDefault();
      $prizeGift = $(e.target).parents('.baseGiftPrize')
      $prizeGiftIdName = $prizeGift.find('.prize_gifts_id').prop('name')
      $prizeGiftIdNameSplit = $prizeGiftIdName.split('][')
      $prizeGiftId = $prizeGiftIdNameSplit[1]
      $prizeGiftDestroy = $('<input>').attr({
        type: 'hidden',
        id: 'tournament_base_entry_attributes_prize_gifts_attributes_' +  ($prizeGiftId) + '__destroy',
        name: "tournament[base_entry_attributes[prize_gifts_attributes][" +  ($prizeGiftId) + "][_destroy]]",
        value: 1,
      }).appendTo($prizeGift);
      //$prizeGift.removeClass('activeEntryLevel');
      $prizeGift.hide();

    });
    $("body").on("click", ".deleteExistingAddedPrizeGift", function (e) {
      e.preventDefault();
      $entryLevelId = $(e.target).parents('.entrylevel').index() - 1
      $prizeGift = $(e.target).parents('.giftPrize')
      $prizeGiftIdName = $prizeGift.find('.prize_gifts_id').prop('name')
      $prizeGiftIdNameSplit = $prizeGiftIdName.split('][')
      $prizeGiftId = $prizeGiftIdNameSplit[4] 
      $prizeGiftDestroy = $('<input>').attr({
        type: 'hidden',
        id: 'tournament_entry_levels_attributes_' + $entryLevelId + '_prize_gifts_attributes_'+ $prizeGiftId +'__destroy',
        name: '[tournament][entry_levels_attributes][' + $entryLevelId + '][prize_gifts_attributes]['+ $prizeGiftId +'][_destroy]',
        value: 1,
      }).appendTo($prizeGift);
      $prizeGift.hide();
    });
  }
// prize gifts functions
  function changeBaseEntryGiftFormDefaultToAddedEntryLevelDefault() {
    $('.additionalEntry')
  }

  function deactiveFirstGiftPrize(prizeGift) {
    prizeGift.addClass('d-none')
    prizeGift.removeClass('activePrizeGift')
    prizeGift.find('.prize_gifts_place').prop("disabled", true)
    prizeGift.find('.prize_gifts_name').prop("disabled", true)
    prizeGift.find('.prize_gifts_description').prop("disabled", true)
    prizeGift.find('.prize_gifts_image').prop("disabled", true)
    prizeGift.find('.prize_gifts_name').val('')
    prizeGift.find('.prize_gifts_description').val('')
    prizeGift.find('.prize_gifts_image').val('')
  }

// end of prize gifts funtions 
// END OF PRIZE GIFTS

  function populateUpdateBaseEntryForm() {
    $(".entrylevels").find('.delete_entrylevel').hide();
    $(".entrylevels").find(".prize_custom_name").val(gon.baseEntry.custom_name);
    $(".entrylevels").find(".prize_rank_style").val(gon.baseEntry.rank_style);
    $(".entrylevels").find('.prize_fishtypes').val([gon.baseEntry.fish_type + "___" + gon.baseEntry.metric]);
    $(".entrylevels").find(".prize_fishtype").val(gon.baseEntry.fish_type);
    $(".entrylevels").find(".prize_metric").val(gon.baseEntry.metric);
    $(".entrylevels").find(".prize_pool").val(gon.baseEntry.prize_pool);
    $(".entrylevels").find(".prize_entryfee").val(gon.baseEntry.price_amount);
    $(".entrylevels").find('.prize_range').val(gon.baseEntry.prize_pool);
    $(".entrylevels").find(".prize_1, .prize_1_value").val(gon.baseEntry.first_place);
    $(".entrylevels").find('.prize_1, .prize_1_value').attr('id', "tournament_base_entry_attributes_prize_1_value");
    $(".entrylevels").find('.prize_1, .prize_1_value').prop('name', 'tournament[base_entry_attributes][first_place]');
    $(".entrylevels").find('#js-rangeslider-0 .rangeslider__fill').css("width", (gon.baseEntry.first_place * 100 /gon.baseEntry.prize_pool)+ "%");
    $(".entrylevels").find('#js-rangeslider-0 .rangeslider__handle').css("left", (gon.baseEntry.first_place * 100 /gon.baseEntry.prize_pool - 5)+"%");
    $(".entrylevels").find(".prize_2, .prize_2_value").val(gon.baseEntry.second_place);
    $(".entrylevels").find('.prize_2, .prize_2_value').attr('id', "tournament[base_entry_attributes_prize_2_value]");
    $(".entrylevels").find('.prize_2, .prize_2_value').prop('name', 'tournament[base_entry_attributes][second_place]');
    $(".entrylevels").find('#js-rangeslider-1 .rangeslider__fill').css("width", (gon.baseEntry.second_place * 100 /gon.baseEntry.prize_pool)+ "%");
    $(".entrylevels").find('#js-rangeslider-1 .rangeslider__handle').css("left", (gon.baseEntry.second_place * 100 /gon.baseEntry.prize_pool - 5)+"%");
    $(".entrylevels").find(".prize_3, .prize_3_value").val(gon.baseEntry.third_place);
    $(".entrylevels").find('.prize_3, .prize_3_value').attr('id', "tournament_base_entry_attributes_prize_3_value");
    $(".entrylevels").find('.prize_3, .prize_3_value').prop('name', 'tournament[base_entry_attributes][third_place]');
    $(".entrylevels").find('#js-rangeslider-2 .rangeslider__fill').css("width", (gon.baseEntry.third_place * 100 /gon.baseEntry.prize_pool)+ "%");
    $(".entrylevels").find('#js-rangeslider-2 .rangeslider__handle').css("left", (gon.baseEntry.third_place * 100 /gon.baseEntry.prize_pool - 5) + "%");

    $(".entrylevels").find(".rangebox.rangebox--disabled").removeClass("rangebox--disabled");



    //Populate base entry gifts 

    var baseEntryGifts = gon.baseEntryGifts
    for (let index = 0; index < baseEntryGifts.length; index++) {
      const prizeGift = baseEntryGifts[index][0];
      const image_url = baseEntryGifts[index][1];
      var prizeGiftContainer
      switch(prizeGift.place) {
        case "first":
          prizeGiftContainer = $(".entrylevels").find('.prizeGiftContainerFirstPlace')
          break;
        case "second":
          prizeGiftContainer = $(".entrylevels").find('.prizeGiftContainerSecondPlace')
          break;
        case "third":
          prizeGiftContainer = $(".entrylevels").find('.prizeGiftContainerThirdPlace')
          break;
        case "entry":
          prizeGiftContainer = $(".entrylevels").find('.prizeGiftContainerEntry')
          break;
        default:
      }
      var prizeGiftIndex = prizeGiftContainer.parents('.prizeContainer').find('.giftPrize').length
      var $newPrizeGift = prizeGiftContainer.parents('.entrylevel').find('.prizeGiftContainerTemplate .giftPrize').clone()
      $newPrizeGift.removeClass('d-none')
      $newPrizeGift.addClass('activePrizeGift')
      $newPrizeGift.find('.deleteNewPrizeGift').addClass('deleteExistingBasePrizeGift')
      $newPrizeGift.find('.deleteNewPrizeGift').removeClass('deleteNewPrizeGift')
      $newPrizeGift.find('.prize_gifts_name').prop("disabled", false)
      $newPrizeGift.find('.prize_gifts_description').prop("disabled", false)
      $newPrizeGift.find('.prize_gifts_image').prop("disabled", false)
      $newPrizeGift.find('.prize_gifts_place').prop("disabled", false)
      $newPrizeGift.find('.prize_gifts_place').prop("name", 'tournament[base_entry_attributes[prize_gifts_attributes]['+prizeGiftIndex+'][place]]')
      $newPrizeGift.find('.prize_gifts_name').prop("name", 'tournament[base_entry_attributes[prize_gifts_attributes]['+prizeGiftIndex+'][name]]')
      $newPrizeGift.find('.prize_gifts_description').prop("name", 'tournament[base_entry_attributes[prize_gifts_attributes]['+prizeGiftIndex+'][description]]')
      $newPrizeGift.find('.prize_gifts_image').prop("name", 'tournament[base_entry_attributes[prize_gifts_attributes]['+prizeGiftIndex+'][image]]')
      $newPrizeGift.find('.prize_gifts_place').prop("id", 'tournament_base_entry_attributes_prize_gifts_attributes_'+prizeGiftIndex+'_place')
      $newPrizeGift.find('.prize_gifts_name').prop("id", 'tournament_base_entry_attributes_prize_gifts_attributes_'+prizeGiftIndex+'_name')
      $newPrizeGift.find('.prize_gifts_description').prop("id", 'tournament_base_entry_attributes_prize_gifts_attributes_'+prizeGiftIndex+'_description')
      $newPrizeGift.find('.prize_gifts_image').prop("id", 'tournament_base_entry_attributes_prize_gifts_attributes_'+prizeGiftIndex+'_image')
      $newPrizeGift.find('.prize_gifts_place').val(prizeGift.place)
      $newPrizeGift.find('.prize_gifts_name').val(prizeGift.name)
      $newPrizeGift.find('.prize_gifts_description').val(prizeGift.description)
      $newPrizeGift.find('.prize_gifts_image').val(prizeGift.image)

      if(!(prizeGift.image)){
        $newPrizeGift.find('.prize_gifts_image').removeAttr('required')
      }
      var $prizeGiftImage = $('<img>').attr({
        src: image_url,
        class: 'image-fluid img-thumbnail prizeGiftImage',
      });
      if($prizeGiftImage.prop('src')){
        $prizeGiftImage.appendTo($newPrizeGift.find('.prizeGiftImageConatiner'));
      }

      $newPrizeGiftId = $('<input>').attr({
        type: 'hidden',
        id: 'tournament_base_entry_attributes_prize_gifts_attributes[' + prizeGift.id + ']',
        name: 'tournament[base_entry_attributes[prize_gifts_attributes]['+prizeGiftIndex+'][id]]',
        value: prizeGift.id,
        class: "prize_gifts_id"
      }).appendTo($newPrizeGift);
      $newPrizeGift.appendTo(prizeGiftContainer)
    }


  }

  function populateUpdateEntryLevelForm() {
    for (let entryLevel = 0; entryLevel < gon.entryLevels.length; entryLevel++) {
      const element = gon.entryLevels[entryLevel];
      var $entrylevels = $('.entrylevels');
      var $newentrylevel = $entrylevels.find('.baseEntry').clone();
      var $newentrylevel_count = $entrylevels.find('.prizecard').length - 1;
      var $newentrylevel_name = 'tournament[entry_levels_attributes][' + $newentrylevel_count + ']';
      $newentrylevel.find('.prizeGiftContainer .baseGiftPrize').remove()
      $newentrylevel.removeClass('baseEntry');
      $newentrylevel.addClass('additionalEntry');
      $newentrylevel.removeClass('border-primary');
      $newentrylevel.find('.entrylevel-header').removeClass('text-white bg-primary');
      $newentrylevel.find('.entrylevel-header h6').html('Additional Entry Level');
      $newentrylevel.find('.delete_entrylevel').show();

      $newentrylevel.find('.prize_isbase').remove();
      $newentrylevel.find('.delete_entrylevel').removeClass("delete_entrylevel").addClass("delete_existing_entrylevel")
      
      $newentrylevel.find('.prize_custom_name').val(element.custom_name);
      $newentrylevel.find('.prize_custom_name').attr('id', "tournament_entry_level_attributes_custom_name[" + $newentrylevel_count + "]");
      $newentrylevel.find('.prize_rank_style').val(element.rank_style);
      $newentrylevel.find('.prize_rank_style').attr('id', "tournament_entry_level_attributes_rank_style[" + $newentrylevel_count + "]");
      $newentrylevel.find('.prize_fishtypes').val([element.fish_type + "___" + element.metric]);
      $newentrylevel.find('.prize_fishtype').val(element.fish_type);
      $newentrylevel.find('.prize_fishtype').attr('id', "tournament_entry_level_attributes_fish_type[" + $newentrylevel_count + "]");
      $newentrylevel.find('.prize_metric').val(element.metric);
      $newentrylevel.find('.prize_metric').attr('id', "tournament_entry_level_attributes_metric[" + $newentrylevel_count + "]");
      $newentrylevel.find('.prize_pool').val(element.prize_pool);
      $newentrylevel.find('.prize_pool').attr('id', "tournament_entry_level_attributes_prize_pool[" + $newentrylevel_count + "]");
      $newentrylevel.find('.prize_entryfee').val(element.price_amount);
      $newentrylevel.find('.prize_entryfee').attr('id', "tournament_entry_level_attributes_price_amount[" + $newentrylevel_count + "]");
      $newentrylevel.find('.rangeslider').remove();
      $newentrylevel.find('.prize_range').val(element.prize_pool);
      $newentrylevel.find('.prize_1, .prize_1_value').prop('value', element.first_place);
      $newentrylevel.find('.prize_1, .prize_1_value').attr('id', "tournament_entry_level_attributes_prize_1_value[" + $newentrylevel_count + "]");
      $newentrylevel.find('.prize_2, .prize_2_value').prop('value', element.second_place);
      $newentrylevel.find('.prize_2, .prize_2_value').attr('id', "tournament_entry_level_attributes_prize_2_value[" + $newentrylevel_count + "]");
      $newentrylevel.find('.prize_3, .prize_3_value').prop('value', element.third_place);
      $newentrylevel.find('.prize_3, .prize_3_value').attr('id', "tournament_entry_level_attributes_prize_3_value[" + $newentrylevel_count + "]");

      $newentrylevel.find('.prize_custom_name').prop('name', $newentrylevel_name + '[custom_name]');
      $newentrylevel.find('.prize_rank_style').prop('name', $newentrylevel_name + '[rank_style]');
      $newentrylevel.find('.prize_fishtype').prop('name', $newentrylevel_name + '[fish_type]');
      $newentrylevel.find('.prize_metric').prop('name', $newentrylevel_name + '[metric]');
      $newentrylevel.find('.prize_pool').prop('name', $newentrylevel_name + '[prize_pool]');
      $newentrylevel.find('.prize_entryfee').prop('name', $newentrylevel_name + '[price_amount]');
      $newentrylevel.find('.prize_1, .prize_1_value').prop('name', $newentrylevel_name + '[first_place]');
      $newentrylevel.find('.prize_2, .prize_2_value').prop('name', $newentrylevel_name + '[second_place]');
      $newentrylevel.find('.prize_3, .prize_3_value').prop('name', $newentrylevel_name + '[third_place]');

      $entryLevelId = $('<input>').attr({
        type: 'hidden',
        id: 'tournament_entry_levels_attributes_id[' + element.id + ']',
        name: $newentrylevel_name + "[id]",
        value: element.id,
        class: "entry_level_id"
      }).appendTo($newentrylevel);
      $newentrylevel.appendTo($entrylevels);

      $entrylevels.find('.prizecard:last-child input[type="range"]').rangeslider({
        polyfill: false,
      });

      $newentrylevel.find('#js-rangeslider-' + (($newentrylevel_count + 1) * 3 ) + ' .rangeslider__fill').css("width", (element.first_place * 100 /element.prize_pool)+ "%");
      $newentrylevel.find('#js-rangeslider-' + (($newentrylevel_count + 1) * 3 ) + ' .rangeslider__handle').css("left", (element.first_place * 100 /element.prize_pool - 5) + "%");
      $newentrylevel.find('#js-rangeslider-' + (($newentrylevel_count + 1) * 3 + 1) + ' .rangeslider__fill').css("width", (element.second_place * 100 /element.prize_pool)+ "%");
      $newentrylevel.find('#js-rangeslider-' + (($newentrylevel_count + 1) * 3 + 1) + ' .rangeslider__handle').css("left", (element.second_place * 100 /element.prize_pool - 5) + "%");
      $newentrylevel.find('#js-rangeslider-' + (($newentrylevel_count + 1) * 3 + 2) + ' .rangeslider__fill').css("width", (element.third_place * 100 /element.prize_pool)+ "%");
      $newentrylevel.find('#js-rangeslider-' + (($newentrylevel_count + 1) * 3 + 2) + ' .rangeslider__handle').css("left", (element.third_place * 100 /element.prize_pool - 5) + "%");
      // prize gift template 
      $newentrylevel.find('.addBaseEntryPrizeGift').addClass('addAdditionalEntryLevelPrizeGift')
      $newentrylevel.find('.addBaseEntryPrizeGift').removeClass('addBaseEntryPrizeGift')
      var $prizeGift = $newentrylevel.find('.prizeGiftContainerTemplate')
      $prizeGift.find('.prize_gifts_place').prop('name', "tournament[entry_levels_attributes][" + $newentrylevel_count + "][prize_gifts_attributes][0][place]")
      $prizeGift.find('.prize_gifts_name').prop('name', "tournament[entry_levels_attributes][" + $newentrylevel_count + "][prize_gifts_attributes][0][name]")
      $prizeGift.find('.prize_gifts_description').prop('name', "tournament[entry_levels_attributes][" + $newentrylevel_count + "][prize_gifts_attributes][0][description]")
      $prizeGift.find('.prize_gifts_image').prop('name', "tournament[entry_levels_attributes][" + $newentrylevel_count + "][prize_gifts_attributes][0][image]")
      //existing prize gift 
      update_fishtypes();


      //Populate entry level gifts 
      var entryLevelGifts = gon.entryLevelsGifts[entryLevel]
      for (let index = 0; index < entryLevelGifts[0].length; index++) {
        const prizeGift = entryLevelGifts[0][index];
        const image_url = entryLevelGifts[1][index];
        var prizeGiftContainer
        switch(prizeGift.place) {
          case "first":
            prizeGiftContainer = $newentrylevel.find('.prizeGiftContainerFirstPlace')
            break;
          case "second":
            prizeGiftContainer = $newentrylevel.find('.prizeGiftContainerSecondPlace')
            break;
          case "third":
            prizeGiftContainer = $newentrylevel.find('.prizeGiftContainerThirdPlace')
            break;
          case "entry":
            prizeGiftContainer = $newentrylevel.find('.prizeGiftContainerEntry')
            break;
          default:
        }
        var prizeGiftIndex = prizeGiftContainer.parents('.prizeContainer').find('.giftPrize').length
        var $newPrizeGift = prizeGiftContainer.parents('.entrylevel').find('.prizeGiftContainerTemplate .giftPrize').clone()
        var entryLevelIndex = prizeGiftContainer.parents('.entrylevel').index() -1
        $newPrizeGift.removeClass('d-none baseGiftPrize')
        $newPrizeGift.addClass('activePrizeGift additionalGiftPrize')
        $newPrizeGift.find('.deleteNewPrizeGift').addClass('deleteExistingAddedPrizeGift')
        $newPrizeGift.find('.deleteNewPrizeGift').removeClass('deleteNewPrizeGift')
        $newPrizeGift.find('.prize_gifts_name').prop("disabled", false)
        $newPrizeGift.find('.prize_gifts_description').prop("disabled", false)
        $newPrizeGift.find('.prize_gifts_image').prop("disabled", false)
        $newPrizeGift.find('.prize_gifts_place').prop("disabled", false)
        $newPrizeGift.find('.prize_gifts_place').prop("name", '[tournament][entry_levels_attributes][' + entryLevelIndex + '][prize_gifts_attributes]['+prizeGiftIndex+'][place]')
        $newPrizeGift.find('.prize_gifts_name').prop("name", '[tournament][entry_levels_attributes][' + entryLevelIndex + '][prize_gifts_attributes]['+prizeGiftIndex+'][name]')
        $newPrizeGift.find('.prize_gifts_description').prop("name", '[tournament][entry_levels_attributes][' + entryLevelIndex + '][prize_gifts_attributes]['+prizeGiftIndex+'][description]')
        $newPrizeGift.find('.prize_gifts_image').prop("name", '[tournament][entry_levels_attributes][' + entryLevelIndex + '][prize_gifts_attributes]['+prizeGiftIndex+'][image]')
        $newPrizeGift.find('.prize_gifts_place').prop("id", 'tournament_entry_levels_attributes_' + entryLevelIndex + '_prize_gifts_attributes_'+prizeGiftIndex+'_place')
        $newPrizeGift.find('.prize_gifts_name').prop("id", 'tournament_entry_levels_attributes_' + entryLevelIndex + '_prize_gifts_attributes_'+prizeGiftIndex+'_name')
        $newPrizeGift.find('.prize_gifts_description').prop("id", 'tournament_entry_levels_attributes_' + entryLevelIndex + '_prize_gifts_attributes_'+prizeGiftIndex+'_description')
        $newPrizeGift.find('.prize_gifts_image').prop("id", 'tournament_entry_levels_attributes_' + entryLevelIndex + '_prize_gifts_attributes_'+prizeGiftIndex+'_image')
        $newPrizeGift.find('.prize_gifts_place').val(prizeGift.place)
        $newPrizeGift.find('.prize_gifts_name').val(prizeGift.name)
        $newPrizeGift.find('.prize_gifts_description').val(prizeGift.description)
        $newPrizeGift.find('.prize_gifts_image').val(prizeGift.image)

        var $prizeGiftImage = $('<img>').attr({
          src: image_url,
          class: 'image-fluid img-thumbnail prizeGiftImage',
        });
        if($prizeGiftImage.prop('src')){
          $prizeGiftImage.appendTo($newPrizeGift.find('.prizeGiftImageConatiner'));
        }

        $newPrizeGiftId = $('<input>').attr({
          type: 'hidden',
          id: 'tournament_entry_levels_attributes_' + entryLevelIndex + '_prize_gifts_attributes_'+prizeGiftIndex+'_id',
          name: '[tournament][entry_levels_attributes][' + entryLevelIndex + '][prize_gifts_attributes]['+prizeGiftIndex+'][id]',
          value: prizeGift.id,
          class: "prize_gifts_id"
        }).appendTo($newPrizeGift);
        $newPrizeGift.appendTo(prizeGiftContainer)
      }
    }
  }

  // TOURNAMENT: ADD NEW ENTRY LEVEL
  $(".addlevel").on("click", function (e) {
    e.preventDefault();

    var $entrylevels = $('.entrylevels');
    var $newentrylevel = $entrylevels.find('.baseEntry').clone();
    var $newentrylevel_count = $entrylevels.find('.prizecard').length - 1;
    var $newentrylevel_name = 'tournament[entry_levels_attributes][' + $newentrylevel_count + ']';

    $newentrylevel.removeClass('baseEntry');
    $newentrylevel.addClass('additionalEntry');
    $newentrylevel.removeClass('border-primary');
    $newentrylevel.find('.entrylevel-header').removeClass('text-white bg-primary');
    $newentrylevel.find('.entrylevel-header h6').html('Additional Entry Level');
    $newentrylevel.find('.delete_entrylevel').show();

    $newentrylevel.find('.prize_isbase').remove();

    $newentrylevel.find('.prize_custom_name').val('');
    $newentrylevel.find('.prize_rank_style').val('');
    $newentrylevel.find('.prize_fishtypes').val('');
    $newentrylevel.find('.prize_fishtype').val('');
    $newentrylevel.find('.prize_metric').val('');
    $newentrylevel.find('.prize_pool').val('');
    $newentrylevel.find('.prize_entryfee').val('');

    $newentrylevel.find('.rangeslider').remove();
    $newentrylevel.find('.prize_range').val('').prop('max', 100);
    $newentrylevel.find('.prize_1').prop('value', 50);
    $newentrylevel.find('.prize_2').prop('value', 20);
    $newentrylevel.find('.prize_3').prop('value', 10);

    $newentrylevel.find('.prize_value').val('');
    $newentrylevel.find('.rangebox').addClass('rangebox--disabled');

    $newentrylevel.find('.prize_custom_name').prop('name', $newentrylevel_name + '[custom_name]');
    $newentrylevel.find('.prize_custom_name').removeAttr('id');
    $newentrylevel.find('.prize_rank_style').prop('name', $newentrylevel_name + '[rank_style]');
    $newentrylevel.find('.prize_rank_style').removeAttr('id');
    $newentrylevel.find('.prize_fishtype').prop('name', $newentrylevel_name + '[fish_type]');
    $newentrylevel.find('.prize_metric').prop('name', $newentrylevel_name + '[metric]');
    $newentrylevel.find('.prize_pool').prop('name', $newentrylevel_name + '[prize_pool]');
    $newentrylevel.find('.prize_entryfee').prop('name', $newentrylevel_name + '[price_amount]');
    $newentrylevel.find('.prize_1').prop('name', $newentrylevel_name + '[first_place]');
    $newentrylevel.find('.prize_1, .prize_1_value').prop('name', $newentrylevel_name + '[first_place]');
    $newentrylevel.find('.prize_1, .prize_1_value').removeAttr('id');
    $newentrylevel.find('.prize_2').prop('name', $newentrylevel_name + '[second_place]');
    $newentrylevel.find('.prize_2, .prize_2_value').prop('name', $newentrylevel_name + '[second_place]');
    $newentrylevel.find('.prize_2, .prize_2_value').removeAttr('id');
    $newentrylevel.find('.prize_3').prop('name', $newentrylevel_name + '[third_place]');
    $newentrylevel.find('.prize_3, .prize_3_value').prop('name', $newentrylevel_name + '[third_place]');
    $newentrylevel.find('.prize_3, .prize_3_value').removeAttr('id');

    //prize gift template
    $newentrylevel.find('.addBaseEntryPrizeGift').addClass('addAdditionalEntryLevelPrizeGift')
    $newentrylevel.find('.addBaseEntryPrizeGift').removeClass('addBaseEntryPrizeGift')
    $newentrylevel.find('.activePrizeGift').remove()
    var $prizeGift = $newentrylevel.find('.prizeGiftContainerTemplate')
    $prizeGift.find('.prize_gifts_place').prop('name', "tournament[entry_levels_attributes][" + $newentrylevel_count + "][prize_gifts_attributes][0][place]")
    $prizeGift.find('.prize_gifts_name').prop('name', "tournament[entry_levels_attributes][" + $newentrylevel_count + "][prize_gifts_attributes][0][name]")
    $prizeGift.find('.prize_gifts_description').prop('name', "tournament[entry_levels_attributes][" + $newentrylevel_count + "][prize_gifts_attributes][0][description]")
    $prizeGift.find('.prize_gifts_image').prop('name', "tournament[entry_levels_attributes][" + $newentrylevel_count + "][prize_gifts_attributes][0][image]")

    

    $newentrylevel.appendTo($entrylevels);

    $entrylevels.find('.prizecard:last-child input[type="range"]').rangeslider({
      polyfill: false,
    });

    update_fishtypes();

  });


  // TOURNAMENT: DELETE ENTRY LEVEL
  $("body").on("click", ".delete_existing_entrylevel", function (e) {
    e.preventDefault();
    var $entrylevels = $('.entrylevels');
    var $entrylevels_count = $entrylevels.find('.activeEntryLevel').length;
    var entryLevelId = $(this).closest('.entrylevel').find('.entry_level_id').prop("name");
    entryLevelId = entryLevelId.split("[");
    entryLevelId = entryLevelId[2].split("]");
    entryLevelId =entryLevelId[0];
    $entryLevelId = $('<input>').attr({
      type: 'hidden',
      id: 'tournament_entry_levels_attributes_' +  (entryLevelId) + '__destroy',
      name: "tournament[entry_levels_attributes][" +  (entryLevelId) + "][_destroy]",
      value: 1,
    }).appendTo('.entrylevel');
    if ($entrylevels_count > 1) {
      $(this).parents('.entrylevel').removeClass('activeEntryLevel');
      $(this).parents('.entrylevel').hide();
    }
  });

  $("body").on("click", ".delete_entrylevel", function (e) {
    e.preventDefault();
    var $entrylevels = $('.entrylevels');
    var $entrylevels_count = $entrylevels.find('.entrylevel').length;
    if ($entrylevels_count > 1) {
      $(this).closest('.entrylevel').removeClass('activeEntryLevel');
      $(this).closest('.entrylevel').hide();
    }

  });

  $("body").on("change", ".prize_fishtypes", function (e) {
    e.preventDefault();
    update_fishtypes();

  });

  $("body").on("input", ".prize_entryfee", function (e) {
    e.preventDefault();
    $(e.target.nextElementSibling).val($(e.target).val())
  });



  // TOURNAMENT: AUTO POPULATE FORM FOR TESTING
  $("body").on("click", ".auto_populate", function (e) {
    e.preventDefault();

    $('#tournament_name').prop('value', 'Random Tournament ' + (""+Math.random()).substring(2,6));
    $('#tournament_tournament_address_attributes_street').prop('value', 'Address 1');
    $('#tournament_tournament_address_attributes_apartment').prop('value', 'Address 2');
    $('#tournament_tournament_address_attributes_city').prop('value', 'City');
    $('#tournament_tournament_address_attributes_state').prop('value', 'AL');
    $('#tournament_tournament_address_attributes_zipcode').prop('value', '12345');

    $('#tournament_description').prop('value', 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ac purus erat. Praesent malesuada mauris ante, a viverra nulla scelerisque quis.');
    $('#tournament_rules').prop('value', 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ac purus erat. Praesent malesuada mauris ante, a viverra nulla scelerisque quis.');

    //$('#tournament_early_starts_at').prop('disabled', '');
    //$('#tournament_early_ends_at').prop('disabled', '');
    $('#tournament_registration_starts_at').prop('disabled', '');
    $('#tournament_registration_ends_at').prop('disabled', '');
    $('#tournament_tournament_start_date').prop('disabled', '');
    $('#tournament_tournament_end_date').prop('disabled', '');

    //$('#tournament_early_starts_at').prop('value', '2021-03-10');
    //$('#tournament_early_ends_at').prop('value', '2021-03-20');
    $('#tournament_registration_starts_at').prop('value', '2021-04-10');
    $('#tournament_registration_ends_at').prop('value', '2021-04-20');
    $('#tournament_tournament_start_date').prop('value', '2021-05-10');
    $('#tournament_tournament_end_date').prop('value', '2021-05-20');
    $('#tournament_line_in_time').prop('value', '10:00');
    $('#tournament_line_out_time').prop('value', '18:00');

    $('.prize_fishtypes option[value=atlantic_salmon___fish_length]').attr('selected','selected');
    $('.prize_fishtype').prop('value', 'atlantic_salmon');
    $('.prize_metric').prop('value', 'fish_length');
    $('.prize_pool').prop('value', '10000');
    $('.prize_entryfee').prop('value', '1000');
    var $prize_pool = $('.prizecard:first-child .prize_pool').val();
    var $prize_card = $('.prizecard:first-child');
    $prize_card.find(".rangebox").removeClass("rangebox--disabled");
    $prize_card.find('input[type="range"]').prop("max", $prize_pool);
    $prize_card.find(".prize_1, .prize_1_value").prop("value", parseFloat($prize_pool * 0.5).toFixed(0));
    $prize_card.find(".prize_2, .prize_2_value").prop("value", parseFloat($prize_pool * 0.2).toFixed(0));
    $prize_card.find(".prize_3, .prize_3_value").prop("value", parseFloat($prize_pool * 0.1).toFixed(0));
    $prize_card.find('input[type="range"]').rangeslider("update", true);

    $('#tournament_first_name').prop('value', 'Firstname');
    $('#tournament_last_name').prop('value', 'Lastname');
    $('#tournament_email').prop('value', 'test@email.com');
    $('#tournament_phone').prop('value', '(212)888-1450');
    $('#tournament_contact_method').prop('value', 'Email');
    $('#tournament_website').prop('value', 'https://www.google.com/');

    $('#accept_terms').prop('checked', 'checked');
    $('#accept_waiver').prop('checked', 'checked');

  });





  // TOURNAMENT: BASIC DATES VALIDATION
  // dates can equal other dates next to them
  // date cant be less than date before them or greater than dates after them
  $("body").on("change", ".tournament-dates", function (e) {
    e.preventDefault();
    while (true) {
      var earlyRegistrationStartDate = Date.parse($("#tournament_early_start_date").val());
      var earlyRegistrationEndDate = Date.parse($("#tournament_early_end_date").val());
      var registrationStartDate = Date.parse($("#tournament_registration_start_date").val());
      var registrationEndDate = Date.parse($("#tournament_registration_end_date").val());
      var tournamentStartDate = Date.parse($("#tournament_tournament_start_date").val());
      var tournamentEndDate = Date.parse($("#tournament_tournament_end_date").val());
      if(earlyRegistrationStartDate > (earlyRegistrationEndDate || registrationStartDate || registrationEndDate || tournamentStartDate || tournamentEndDate)) {
        $("#tournament_early_start_date").val(moment(earlyRegistrationEndDate).add(1, 'days').format("YYYY-MM-DD"));
        earlyRegistrationStartDate = earlyRegistrationEndDate;
      } else if (earlyRegistrationEndDate < earlyRegistrationStartDate) {
        $("#tournament_early_end_date").val(moment(earlyRegistrationStartDate).add(1, 'days').format("YYYY-MM-DD"));
        earlyRegistrationEndDate = earlyRegistrationStartDate;
      } else if (earlyRegistrationEndDate > (registrationStartDate || registrationEndDate || tournamentStartDate || tournamentEndDate)){
        $("#tournament_early_end_date").val(moment(registrationStartDate).add(1, 'days').format("YYYY-MM-DD"));
        earlyRegistrationEndDate = registrationStartDate;
      } else if (registrationStartDate < (earlyRegistrationEndDate || registrationStartDate)){
        $("#tournament_registration_start_date").val(moment(earlyRegistrationEndDate).add(1, 'days').format("YYYY-MM-DD"));
        registrationStartDate = earlyRegistrationEndDate;
      } else if (registrationStartDate > (registrationEndDate || tournamentStartDate || tournamentEndDate)){
        $("#tournament_registration_start_date").val(moment(registrationEndDate).add(1, 'days').format("YYYY-MM-DD"));
        registrationStartDate = registrationEndDate;
      } else if (registrationEndDate < (earlyRegistrationStartDate || earlyRegistrationEndDate || registrationStartDate) ) {
        $("#tournament_registration_end_date").val(moment(registrationStartDate).add(1, 'days').format("YYYY-MM-DD"));
        registrationEndDate = registrationStartDate;
      } else if (registrationEndDate > (tournamentStartDate || tournamentEndDate) ) {
        $("#tournament_registration_end_date").val(moment(tournamentStartDate).add(1, 'days').format("YYYY-MM-DD"));
        registrationEndDate = tournamentStartDate;
      } else if (tournamentStartDate < (earlyRegistrationStartDate || earlyRegistrationEndDate || registrationStartDate || registrationEndDate)) {
        $("#tournament_tournament_start_date").val(moment(registrationEndDate).add(1, 'days').format("YYYY-MM-DD"));
        tournamentStartDate = registrationEndDate;
      } else if (tournamentStartDate > tournamentEndDate) {
        $("#tournament_tournament_start_date").val(moment(tournamentEndDate).add(1, 'days').format("YYYY-MM-DD"));
        tournamentStartDate = tournamentEndDate;
      } else if (tournamentEndDate < (earlyRegistrationStartDate || earlyRegistrationEndDate || registrationStartDate || registrationEndDate || tournamentStartDate)) {
        $("#tournament_tournament_start_date").val(moment(tournamentEndDate).add(1, 'days').format("YYYY-MM-DD"));
        tournamentEndDate = tournamentStartDate;
      } else {
        break;
      }
    }
  });

  $(".delete-photo").on("click", function(e) {
    $(this).parent().hide();
    $('#trip_boat_image').addClass('toggle-required');
  })

  // Manage Participants
  $(document).on('click', '.copy-email', function(event) {
    event.preventDefault();
    var userEmail = $(this).attr("id").toString();

    // Copy text to clipboard
    var textarea = document.createElement('textarea');
    textarea.textContent = userEmail;
    document.body.appendChild(textarea);
    var selection = document.getSelection();
    var range = document.createRange();
    range.selectNode(textarea);
    selection.removeAllRanges();
    selection.addRange(range);

    document.execCommand('copy');
    selection.removeAllRanges();

    document.body.removeChild(textarea);
  });
//tournament host participants management
  $(document).on('click', '.active-participants-nav', function() {
    var $activeEntryLevelTab = $('.active-entry-level-participants-nav');
    var $removedTab = $('.removed-participants-nav');
    var $activeParticipants = $('.active-participants');
    var $removedParticipants = $('.removed-participants');
    var $activeEntryLevelParticipants = $('.active-entry-level-participants');

    $(this).children(".nav-link").addClass("active");

    if ($activeEntryLevelTab) {
      $activeEntryLevelTab.children(".nav-link").removeClass("active");
    }
    if ($removedTab) {
      $removedTab.children(".nav-link").removeClass("active");
    }
    if ($activeParticipants) {
      $activeParticipants.show();
    }
    if ($removedParticipants) {
      $removedParticipants.hide();
    }
    if ($activeEntryLevelParticipants) {
      $activeEntryLevelParticipants.hide();
    }
  });

  $(document).on('click', '.removed-participants-nav', function(e) {
    var $activeEntryLevelTab = $('.active-entry-level-participants-nav');
    var $activeTab = $('.active-participants-nav').children(".nav-link").removeClass("active");
    var $activeParticipants = $('.active-participants');
    var $removedParticipants = $('.removed-participants');
    var $activeEntryLevelParticipants = $('.active-entry-level-participants');

    $(this).children(".nav-link").addClass("active");
    if ($activeEntryLevelTab) {
      $activeEntryLevelTab.children(".nav-link").removeClass("active");
    }
    if ($activeTab) {
      $activeTab.children(".nav-link").removeClass("active");
    }
    if ($activeParticipants) {
      $activeParticipants.hide();
    }
    if ($removedParticipants) {
      $removedParticipants.show();
    }
    if ($activeEntryLevelParticipants) {
      $activeEntryLevelParticipants.hide();
    }
  });

  $(document).on('click', '.active-entry-level-participants-nav', function(e) {
    var $activeTab = $('.active-participants-nav').children(".nav-link").removeClass("active");
    var $removedTab = $('.removed-participants-nav');
    var $activeParticipants = $('.active-participants');
    var $removedParticipants = $('.removed-participants');
    var $activeEntryLevelParticipants = $('.active-entry-level-participants');

    $(this).children(".nav-link").addClass("active");

    if ($activeTab) {
      $activeTab.children(".nav-link").removeClass("active");
    }
    if ($removedTab) {
      $removedTab.children(".nav-link").removeClass("active");
    }
    if ($activeParticipants) {
      $activeParticipants.hide();
    }
    if ($removedParticipants) {
      $removedParticipants.hide();
    }
    if ($activeEntryLevelParticipants) {
      $activeEntryLevelParticipants.show();
      $('.entry-level-participants').hide();
      $('.base_entry_participants').show();

    }
  });

  $(document).on('click', '.entry-level-selected', function(e) {
    var $selected_entry_level = $(e.target).attr("class").split(' ').pop();
    $('.entry-level-selector').html($(e.target).html());
    $('.entry-level-participants').hide();
    $('.'+ $selected_entry_level +'').show();
  });


  //end of tournament host management

  // resize number when it is too large
  $(".number-container").each(function(i ,el){

    let $number = $(this).children('.number-text');
    let $numberCircle = $(this).children('.number-circle-host');
    let $numberContainer = $(this).children('.number-container');

    // if numberCircle is empty check to see if number-circle-user is defined
    if ($numberCircle.length === 0) {
      $numberCircle = $(this).children('.number-circle-user');
    }

    if ($number && $numberCircle && $numberContainer){
      let fontSize = parseInt($number.css('font-size'))
      let paddingTop = 0;
      let paddingBottom = 0;
      while ($number.outerWidth() > ($numberCircle.outerWidth() - 20)) {
        paddingTop += 7
        paddingBottom += 5
        fontSize = fontSize - 10;
        let resized = fontSize + "px"
        let resizedPt = paddingTop + "px"
        let resizedPb = paddingBottom + "px"

        $number.css('font-size', resized)
        $(this).css('padding-top', resizedPb)
        $(this).css('padding-bottom', resizedPt)
      }
    }
  });

  //Tournament Sponsors

  $(".add_tournament_sponsor").on("click", function (e) {
    e.preventDefault();
    var $tournamentSponsors = $(".tournament-sponsors");
    var $newTournamentSponsor = $tournamentSponsors.find('.gallery-wrapper:first-child').clone();
    var $tournamentSponsorsCount = $tournamentSponsors.find('.gallery-wrapper').length;
    var $tournamentSponsorNewName = 'tournament[tournament_sponsors_attributes][' + $tournamentSponsorsCount + ']';



    $newTournamentSponsor.find('.delete-existing-tournament-sponsor').removeClass('delete-existing-tournamnet-sponsor')
    $newTournamentSponsor.find('.sponsor_title_number').html('Sponsor #' + ($tournamentSponsorsCount+1));
    $newTournamentSponsor.find('.tournament_sponsors_company_name').val("");
    $newTournamentSponsor.find('.tournament_sponsors_website_url').val("");
    $newTournamentSponsor.find('.tournament_sponsors_image').val('');

    $newTournamentSponsor.find('.tournament_sponsors_company_name').prop("name", $tournamentSponsorNewName + "[company_name]");
    $newTournamentSponsor.find('.tournament_sponsors_website_url').prop("name", $tournamentSponsorNewName + "[website_url]");
    $newTournamentSponsor.find('.tournament_sponsors_image').prop("name", $tournamentSponsorNewName + "[image]");
    $newTournamentSponsor.find('.tournament_sponsors_image_label').html("Choose Image");

    $newTournamentSponsor.find('.tournament_sponsors_image')
    $newTournamentSponsor.appendTo($tournamentSponsors);
    $newTournamentSponsor.find('.tounrment-sponsor-image-render').remove();
    $newTournamentSponsor.show();
  })

  $(document).on("click", ".delete-tournament-sponsor", function (e) {
    e.preventDefault();
    $(this).parents().eq(2).hide();
    $(this).parents().eq(2).find('.tournament_sponsors_company_name').val('');
    $(this).parents().eq(2).find('.tournament_sponsors_website_url').val('');
    $(this).parents().eq(2).find('.tournament_sponsors_image').val('');
    $(this).parents().eq(2).find('.tournament_sponsors_company_name').removeAttr('required');
    $(this).parents().eq(2).find('.tournament_sponsors_website_url').removeAttr('required');
    $(this).parents().eq(2).find('.tournament_sponsors_image').removeAttr('required');
  })

  if ($('.tournaments_edit').length){
    if(gon.tournament_sponsors.length) {
      populateUpdateTournamentSponsors();
    }
  }
  function populateUpdateTournamentSponsors() {
    var element = gon.tournament_sponsors[0][0];
    $('.tournament-sponsors').find('.delete-tournament-sponsor').addClass('delete-existing-tournament-sponsor')

    $('.tournament-sponsors').find('.tournament_sponsors_company_name').attr("id", "tournament_tournament_sponsors_attributes_company_name[0]");
    $('.tournament-sponsors').find('.tournament_sponsors_website_url').attr("id", "tournament_tournament_sponsors_attributes_website_url[0]");
    $('.tournament-sponsors').find('.tournament_sponsors_image').attr("id", "tournament_tournament_sponsors_attributes_image[0]");
    $('.tournament-sponsors').find('.tournament_sponsors_company_name').val(element.company_name);
    $('.tournament-sponsors').find('.tournament_sponsors_website_url').val(element.website_url);

    var $sponsorImage = $('<img>').attr({
      src: gon.tournament_sponsors[0][1],
      class: 'image-fluid img-thumbnail tounrment-sponsor-image-render',
    });
    if($sponsorImage.prop('src') != ''){
      $sponsorImage.appendTo('.sponsor-img');
    }
    var $entryLevelId = $('<input>').attr({
      type: 'hidden',
      id: 'tournament_tournament_sponsors_attributes_id[0]',
      name: "tournament[tournament_sponsors_attributes][0][id]",
      value: element.id
    }).appendTo('.tournament-sponsors');

    for (let sponsor = 1; sponsor < gon.tournament_sponsors.length; sponsor++) {
      const element = gon.tournament_sponsors[sponsor][0];
      var $tournamentSponsors = $(".tournament-sponsors");
      var $newTournamentSponsor = $tournamentSponsors.find('.gallery-wrapper:first-child').clone();
      var $tournamentSponsorsCount = $tournamentSponsors.find('.gallery-wrapper').length;
      var $tournamentSponsorNewName = 'tournament[tournament_sponsors_attributes][' + $tournamentSponsorsCount + ']';
      $newTournamentSponsor.find('.sponsor_title_number').html('Sponsor #' + (sponsor + 1))
      $newTournamentSponsor.find('.delete-tournament-sponsor').addClass('delete-existing-tournament-sponsor')
      $newTournamentSponsor.find('.tournament_sponsors_company_name').val(element.company_name);
      $newTournamentSponsor.find('.tournament_sponsors_website_url').val(element.website_url);

      $newTournamentSponsor.find('.tournament_sponsors_company_name').prop("name", $tournamentSponsorNewName + "[company_name]");
      $newTournamentSponsor.find('.tournament_sponsors_website_url').prop("name", $tournamentSponsorNewName + "[website_url]");
      $newTournamentSponsor.find('.tournament_sponsors_image').prop("name", $tournamentSponsorNewName + "[image]");

      $newTournamentSponsor.find('.tournament_sponsors_company_name').attr("id", "tournament_tournament_sponsors_attributes_company_name[" + sponsor + "]");
      $newTournamentSponsor.find('.tournament_sponsors_website_url').attr("id", "tournament_tournament_sponsors_attributes_website_url[" + sponsor + "]");
      $newTournamentSponsor.find('.tournament_sponsors_image').attr("id", "tournament_tournament_sponsors_attributes_image[" + sponsor + "]");

      $newTournamentSponsor.find('.tournament_sponsors_image')
      $newTournamentSponsor.appendTo($tournamentSponsors);
      $newTournamentSponsor.find('.tounrment-sponsor-image-render').remove();
      var $sponsorImage = $('<img>').attr({
        src: gon.tournament_sponsors[sponsor][1],
        class: 'image-fluid img-thumbnail tounrment-sponsor-image-render',
      });
      if($sponsorImage.prop('src')){
        $sponsorImage.appendTo($newTournamentSponsor.find('.sponsor-img'));
      }
      $newTournamentSponsor.append('<hr />');
      var $entryLevelId = $('<input>').attr({
        type: 'hidden',
        id: 'tournament_tournament_sponsors_attributes_id[' + sponsor + ']',
        name: "tournament[tournament_sponsors_attributes][" + sponsor + "][id]",
        value: element.id
      }).appendTo('.tournament-sponsors');
    }
  }

  $(document).on("click", ".delete-existing-tournament-sponsor", function (e) {
    e.preventDefault();
    var $tournamentSponsor = $(this).closest(".gallery-wrapper");
    $tournamentSponsor.find('.tournament_sponsors_image').val("");

    var tournamentSponsorName = $tournamentSponsor.find(".tournament_sponsors_company_name").prop("name");
    var tournamentSponsorId = tournamentSponsorName.split("[")
    tournamentSponsorId = tournamentSponsorId[2].split("]")
    tournamentSponsorId = tournamentSponsorId[0]
    $tournamentSponsorFormId = $('<input>').attr({
      type: 'hidden',
      id: 'tournament_tournament_sponsors_attributes' +  (tournamentSponsorId) + '__destroy',
      name: "tournament[tournament_sponsors_attributes][" +  (tournamentSponsorId) + "][_destroy]",
      value: 1
    }).appendTo('.tournament-sponsors');
  })

  $(function () {
    $('.tournament-sponsors-tooltip').tooltip()
  });

  function validatesTournamentSponsor() {
    var $sponsorNames = $(".tournament_sponsors_company_name");
    // iterates through sponsor input fields
    for (let sponsorCounter = 0; sponsorCounter < $sponsorNames.length; sponsorCounter++) {
      const companyName = $sponsorNames[sponsorCounter]
      if($(companyName).val()){
        $("[name='tournament[tournament_sponsors_attributes][" + sponsorCounter + "][company_name]'").prop('required', true);
        // list of existing tournament sponsors
        var tournamentSponsors = gon.tournament_sponsors
        // if (any tournament sponsors && if certain tournament sponsor exists && if tournament sponsor has image)
        if(!(tournamentSponsors && tournamentSponsors[sponsorCounter] && tournamentSponsors[sponsorCounter][1])){
          $("[name='tournament[tournament_sponsors_attributes][" + sponsorCounter + "][image]'").prop('required', true);
        }
      }
    }
    var $sponsorWebsiteURL = $(".tournament_sponsors_website_url");
    // iterates through sponsor input fields
    for (let sponsorCounter = 0; sponsorCounter < $sponsorWebsiteURL.length; sponsorCounter++) {
      const websiteURL = $sponsorWebsiteURL[sponsorCounter]
      if($(websiteURL).val()){
        $("[name='tournament[tournament_sponsors_attributes][" + sponsorCounter + "][company_name]'").prop('required', true);
        // list of existing tournament sponsors
        var tournamentSponsors = gon.tournament_sponsors
        // if (any tournament sponsors && if certain tournament sponsor exists && if tournament sponsor has website url)
        if(!(tournamentSponsors && tournamentSponsors[sponsorCounter] && tournamentSponsors[sponsorCounter][1])){
          $("[name='tournament[tournament_sponsors_attributes][" + sponsorCounter + "][image]'").prop('required', true);
        }
      }
    }
    var $sponsorImages = $(".tournament_sponsors_image");
    // iterates through sponsor input fields
    for (let sponsorCounter = 0; sponsorCounter < $sponsorImages.length; sponsorCounter++) {
      const image = $sponsorImages[sponsorCounter]
      if($(image).val()){
        $("[name='tournament[tournament_sponsors_attributes][" + sponsorCounter + "][company_name]'").prop('required', true);
        $("[name='tournament[tournament_sponsors_attributes][" + sponsorCounter + "][image]'").prop('required', true);
      }
    }
  }

  // tournament submit buttons
  $(document).on("click", ".publish_tournament", function (e) {
    $('.toggle-required').prop('required', true);
    validatesTournamentSponsor();
    $(this).submit();
  });
  $(document).on("click", ".draft_tournament", function (e) {
    $('.toggle-required').removeAttr('required');
    $('.tournament_sponsors_company_name').removeAttr('required');
    $('.tournament_sponsors_website_url').removeAttr('required');
    $('.tournament_sponsors_image').removeAttr('required');
    $('body').find('.prize_gifts_name').removeAttr('required');
    $('body').find('.prize_gifts_description').removeAttr('required');
    $('body').find('.prize_gifts_image').removeAttr('required');
    $(this).submit();
  });

});
