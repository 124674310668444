$(document).on("turbolinks:load", function () {
  // TOURNAMENT REGISTRATION
  
  
  $("body").on("click", ".legal-checkbox-wrapper", function() {
    var legal_checkboxes = $('.legal-checkbox-wrapper input[type="checkbox"]');

    if (legal_checkboxes.length === legal_checkboxes.filter(":checked").length) {
      $('.submit-entry-levels').show();
      $('.legal-alert').hide();
    } else {
      $('.submit-entry-levels').hide();
      $('.legal-alert').show();
    }
  });

  var onTournamentRegistrationPage = $('#tournament-registration').length;
  if (!onTournamentRegistrationPage) {
    return;
  }

  if (Stripe && gon.stripe_publishable_api) {
    var stripe = Stripe(gon.stripe_publishable_api);
  };

  if (stripe) {
    var elements = stripe.elements();
    var clientSecret = '';
    var cardElement = $("#card-element");
    
    // If the element doesn't exist, it returns an empty array
    if (cardElement.length) {
      // Stripe specific functions  
      var style = {
        base: {
          color: "#32325d",
        }
      };

      var card = elements.create("card", { style: style });
      card.mount("#card-element")

      card.on('change', ({error}) => {
        let displayError = document.getElementById('card-errors');
        if (error) {
          displayError.textContent = error.message;
        } else {
          displayError.textContent = '';
        }
      });
    };

    $("body").on("click", ".additional-checkbox, .entry-level-checkbox", function (e) {
      var id = $(this).attr('id');
      var checkbox = $(`#${id}.entry-level-checkbox`) || null;
      if (checkbox.is(':checked') ) {
        $(`#${id}.entry-level-checkbox`).prop( "checked", false );
      } else {
        $(`#${id}.entry-level-checkbox`).prop( "checked", true );
      }
    });

    $("body").on("click", ".submit-entry-levels", function (e) {
      var selectedEntryLevelIds = [];
      var selectedEntryLevels = $('.entry-level-checkbox:checkbox:checked');
      var tournamentId = $('.tournament-id-hidden') || null;

      if (selectedEntryLevels != null && tournamentId != null) {
        $(selectedEntryLevels).each(function( index ) {
          selectedEntryLevelIds.push(selectedEntryLevels[index].id);
        });
        if (selectedEntryLevelIds.length) {
          $.ajax({
            url: `/tournaments/${tournamentId.val()}/payment_intent`,
            type: "PUT",
            data: {selected_entry_levels_ids: selectedEntryLevelIds},
            success: function(resp){
              clientSecret = resp.client_secret;
              totalPrice= resp.total_amount;
              if (clientSecret !== '') {
                $('.screen-1').hide();
                $('#entry-level-selection').removeClass("active");
                $('#payment-info').addClass("active");
                $('.screen-2').show();
                $('.submit-payment').prop('disabled', false);
                $(".total-price").text(totalPrice);
              } else {
                //To Do: More intelligent error handling 
                //reload page if client secret does not have value
                location.reload();
              }
            },
            error: function(e) {
              //To Do: More intelligent error handling
              alert("There was an error processing your payment. Please refresh the page.")
            }
          }).catch(function(e) {
            //To Do: More intelligent error handling
            alert("There was an error processing your payment. Please refresh the page.")
          });
        } else {
          alert("You have either forgotten to select an entry level or you have already purchased all entry levels.")
        }
      }
    });
    
    $("body").on("click", ".submit-payment", function (e) {
      e.preventDefault();
      var cardName = $('.billing-name').value
      
      $('.submit-text').hide();
      $('.submit-spinner').show();
      
      stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: card,
          billing_details: {
            name: cardName,
          }
        },
        setup_future_usage: 'off_session'
      }).then(function(result) {
        if (result.error) {
          // Show error to your customer
          alert(result.error.message);
          $('.submit-spinner').hide();
          $('.submit-text').show();
        } else {
          if (result.paymentIntent.status === 'succeeded') {
            // On success go to confirmation and notify user of email
            $('.screen-2').hide();
            $('#payment-info').removeClass("active");
            $('#payment-confirmation').addClass("active");
            $('.screen-3').show();
            
            // success handled with webhooks in app/services/stripe/event_handler.rb
            
          } else {
            //To Do: More intelligent error handling
            alert("There was an error processing your payment. Please refresh the page.");
            $('.submit-spinner').hide();
            $('.submit-text').show();
          }
        }
      });
    });
  };
});
