$(document).on("turbolinks:load", function () {

  if($(".anglers_gallery").length) {
  // Create an empty file list outside of the change event
  let allFiles = new DataTransfer();
  // When the user selects file(s) for gallery images
  $(document).on('change', '#user_gallery_images', function() {
    // Declare the files into a more easily readable variable
    const currentFiles = $(this)[0].files;
    // Loop through the files that were just selected by the user
    // and add them to the allFiles variable outside of this event
    for (let i = 0; i < currentFiles.length; i++) {
      allFiles.items.add(currentFiles[i]);
    }
    // Overwrite the files attached with the files from allFiles
    // This is how files are added instead of replaced
    $(this)[0].files = allFiles.files;
    // If they exist, remove the current carousel pages to start fresh
    $('.carousel-item').remove();
    // If there are any files selected
    if ($(this)[0].files.length){
      // Four images per page, this is the page number
      let carouselItemNum = 0;
      // Insert a full-width flex row into the carousel page
      $('.carousel-inner').append('<div class="carousel-item active"><div class="w-100 row carousel-item-container-0"></div></div>');
      // Loop through the files
      for (let i = 0; i < $(this)[0].files.length; i++) {
        // If we've already put 4 images on a page
        if (i > 0 && i % 4 === 0) {
          // Increment the page number and insert a new page
          carouselItemNum++;
          $('.carousel-inner').append('<div class="carousel-item"><div class="w-100 row carousel-item-container-' + carouselItemNum + '"></div></div>');
        }
        // Insert the image column with special IDs for deleting
        $('.carousel-item-container-' + carouselItemNum).append(
          '<div class="col-12 col-md-3 my-3" id="gallery-image-' + i + '">' + 
          '<a class="btn btn-block btn-danger" id="gallery-image-delete" delete-this="' + i + '">Delete</a>' + 
          '<img class="w-100" src="' + window.URL.createObjectURL(this.files[i]) + '">' + 
          '</div>'
          )
      }
      // Unhide the carousel
      $('.carousel').show();
    }
  });

  // If the delete button is clicked on an image
  $(document).on('click', '#gallery-image-delete', function(e) {
    // Declaring the index of the image to delete using the delete-this prop on the link
    const indexToDelete = Number($(this).attr('delete-this'));
    // Can't iterate through the file list like a normal array
    // so we need to create a DataTransfer, iterate on that, and overwrite the file list with it
    const dt = new DataTransfer();
    const files = $('#user_gallery_images')[0].files;
    for (let i = 0; i < files.length; i++) {
      // Copy every file over except for the one we want to delete
      if (i !== indexToDelete) dt.items.add(files[i]);
    }
    // Overwrite the file list
    $('#user_gallery_images')[0].files = dt.files;

    // Now we re-render the carousel items so that we can "move" the images in the next page back to the current one
    $('.carousel-item').remove();

    if (dt.files.length) {
      const updatedFiles = $('#user_gallery_images')[0].files;
      // Updating the allFiles variable outside of the parent event so that we can continue to add instead of replace files
      allFiles = new DataTransfer();
      for (let i = 0; i < updatedFiles.length; i++) {
        allFiles.items.add(updatedFiles[i]);
      }
      // Same loop from above to create carousel pages
      carouselItemNum = 0;
      $('.carousel-inner').append('<div class="carousel-item active"><div class="w-100 row carousel-item-container-0"></div></div>');
      for (let i = 0; i < updatedFiles.length; i++) {
        if (i > 0 && i % 4 === 0) {
          carouselItemNum++;
          $('.carousel-inner').append('<div class="carousel-item"><div class="w-100 row carousel-item-container-' + carouselItemNum + '"></div></div>');
        }
        $('.carousel-item-container-' + carouselItemNum).append(
          '<div class="col-12 col-md-3 my-3" id="gallery-image-' + i + '">' + 
          '<a class="btn btn-block btn-danger" id="gallery-image-delete" delete-this="' + i + '">Delete</a>' + 
          '<img class="w-100" src="' + window.URL.createObjectURL(updatedFiles[i]) + '">' + 
          '</div>'
        )
      }
    } else {
      $('.carousel').hide();
    }
  })
  }
});