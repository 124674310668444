$(document).on("turbolinks:load", function () {
  $("#user-settings-image-upload")
    .dropzone({
      paramName: "user[image]",
      method: "patch",
      withCredentials: true,
      addRemoveLinks: true,
      uploadMultiple: false,
      maxFiles: 1,
      acceptedFiles: 'image/png,image/jpg,image/jpeg',
      init: function () {
        var myDropzone = this;

        myDropzone.on("sending", function (file, xhr, formData) {
          // formData.append("_method", "patch");
          // formData.append("authenticity_token", $("#authenticity_token").val());
        });

        myDropzone.on("success", function () {});

        myDropzone.on("error", function(file, error) {
          $('.dz-error-message span').text(error)
        })

        myDropzone.on("removedfile", function() {
          $('.dz-error-message span').text('')
        })

        myDropzone.on("addedfile", function(file) {
          const {files} = myDropzone;
          for (let i = 0; i < files.length; i++) {
            if (files[i].dataURL !== file.dataURL) {
              myDropzone.removeFile(files[i])
            }
          }
        })
      }
    })
    .addClass("dropzone");
});
