$(document).on("turbolinks:load", function () {
  $(function () {
    $('.captain-guide-tooltip').tooltip()
  });

  $('#user_is_pending_captain_true, #user_is_pending_guide_true, #neither_captain_guide').on("change", function(event) {
    var radioIds = ['user_is_pending_captain_true', 'user_is_pending_guide_true', 'neither_captain_guide'];
    for (let i = 0; i < radioIds.length; i++) {
      if (event.target.id !== radioIds[i]) {
        $('#' + radioIds[i]).prop('checked', false)
      }
    }

    var captainSelected = $('#user_is_pending_captain_true').is(':checked');
    if (captainSelected) {
      $('.captain-guide-info').text("A Captain is a USCG licensed boat captain who takes passengers on trips. Captains fee is $1,000 per year.");
      $('.captain-guide-license-section').removeClass('d-none');
      $('.captain-guide-referred_by-section').removeClass('d-none');
    } else {
      $('.captain-guide-license-section').addClass('d-none');
      $('.captain-guide-referred_by-section').addClass('d-none');
    }

    var guideSelected = $('#user_is_pending_guide_true').is(':checked');
    if (guideSelected) {
      $('.captain-guide-info').text("A Guide is someone who takes people finishing, helps other’s fish and knows the area. Guides fee is $650 per year.");
      $('.captain-guide-referred_by-section').removeClass('d-none');
    } 

    var neitherSelected = $('#neither_captain_guide').is(':checked');
    if (neitherSelected) {
      $('.captain-guide-info').text("A Captain is a USCG licensed boat captain who takes passengers on trips. Captains fee is $1,000 per year. A Guide is someone who takes people finishing, helps other’s fish and knows the area. Guides fee is $650 per year.");
      $('.captain-guide-referred_by-section').addClass('d-none');
    }
  });
})