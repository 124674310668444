(function($) {

  $.fn.selectpickerFilter = function( options ) {

    var getCurrentPath = function () {
      return (window.location.pathname + window.location.search).replace(/page=[0-9]*/ig, "");
    };

    var addActionsBox = function(element) {
      element.attr('data-actions-box', 'true');
      element.selectpicker();
    };

    var addApplyFilterButton = function(element) {
      var container = element.parent();
      var box = $(container.find('.bs-actionsbox'));
      var inner = box.parent();
      box.addClass('text-center');
      box.html('<a class="btn btn-primary btn-apply-filter" href="' + getCurrentPath() + '">Apply Filter</a>');
      box = box.detach();
      inner.append(box);
    };

    var addApplyFilterEvent = function(element) {
      element.on('change', function (e) {
        var element = $(this);
        var name = element.prop('name');
        var selected = element.val();
        var container = element.parent().parent();
        var menu = $(container.find('.dropdown-menu'));
        var link = $(container.find('a'));
        var current_url = new URL(window.location.href);
        var search_params = current_url.searchParams;
        search_params.delete("page");

        if(selected.length > 0) {
          search_params.set(name, selected.join(','));
        } else {
          search_params.delete(name);
        }

        link.attr('href', current_url.pathname + '?' + search_params.toString());
      });
    };

    return this.each( function() {
      var element = $(this);
      addActionsBox(element);
      addApplyFilterButton(element);
      addApplyFilterEvent(element);
      
    });

  }

}(jQuery));
